<template>
  <div>
    <b-card no-body>
      <b-row>
        <b-col sm="12">
          <b-tabs vertical nav-wrapper-class="nav-vertical">
            <b-tab title="Lama Penyinaran" active><lamapenyinaran /></b-tab>
            <b-tab title="Aktinograph "> <aktinograph /></b-tab>
            <b-tab title="Thermohigrograph"> <thermohigrograph /></b-tab>
            <b-tab title="Form Hujan"> <hujan /> </b-tab>
            <b-tab title="Barograph"> <barograph /></b-tab>
            <b-tab title="Form Perawanan"> <perawanan /></b-tab>
            <b-tab title="Intentitas Hujan"> <hujanhellman /></b-tab>
            <b-tab title="Kelembaban Tanah"> <kelembabantanah /> </b-tab>
            <b-tab title="Fenologi"> </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BTab, BTabs, BRow, BCol, BCard } from "bootstrap-vue";
import lamapenyinaran from "./Lamapenyinaran.vue";
import Aktinograph from "./Aktinograph.vue";
import Thermohigrograph from "./Thermohigrograph.vue";

import Hujan from "./Hujan.vue";
import Barograph from "./Barograph.vue";
import Perawanan from "./Perawanan.vue";
import Hujanhellman from "./Hujanhellman.vue";
import Kelembabantanah from "./Kelembabantanah.vue";
//import Kelembabantanah from "./Kelembabantanah.vue";

export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BTabs,
    lamapenyinaran: lamapenyinaran,
    aktinograph: Aktinograph,
    thermohigrograph: Thermohigrograph,
    hujan: Hujan,
    barograph: Barograph,
    perawanan: Perawanan,
    hujanhellman: Hujanhellman,
    kelembabantanah: Kelembabantanah,
  },
};
</script>
<style>
.nav-pills .nav-link,
.nav-tabs .nav-link {
  justify-content: left;
}
</style>

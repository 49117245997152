<template>
  <div>
    <b-card no-body>
      <b-row>
        <b-col sm="6">
          <!-- card1 -->
          <b-card no-body class="border">
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon icon="FolderIcon" size="14" />
                <span class="align-middle ml-50 text-dark h6">Pibal</span>
              </b-card-title>
            </b-card-header>
            <b-row class="">
              <b-col cols="12">
                <b-table :items="items" :fields="fields" thead-class="text-center" small>
                  <template #cell(atribut)="row">
                    <span class="text-dark small w-100"> {{ row.item.atribut }}</span>
                  </template>

                  <template #cell(label)="row">
                    <!-- <b-form-input size="sm" type="text" v-model="row.item.label" class="text-center" /> -->
                    <span class="text-dark small w-100"> {{ row.item.label }}</span>
                  </template>

                  <template #cell(tooltips)="row">
                    <!-- <b-form-input size="sm" type="text" v-model="row.item.tooltips" class="text-center" /> -->
                    <b-form-textarea id="textarea-small" v-model="row.item.tooltips" size="sm" placeholder="Textarea" class="htarea" />
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <!-- Action Buttons -->
        <b-col cols="12">
          <b-button variant="primary" class="mr-2 float-right" size="sm">Save</b-button>
          <b-button @click.prevent="update" variant="warning" class="mr-2 float-right" size="sm">Update</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BCardHeader, BCardTitle, BFormTextarea, BCardText, BTable } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormTextarea,
    BCardText,
    vSelect,
    BTable,
  },

  data() {
    return {
      fields: ["atribut", "label", "tooltips"],
      items: [
        { atribut: "atribute_123456789", label: "Pengenal Data Angin", tooltips: "kecepatan dalam satuan knot dengan bilangan bulat, jika calm input dengan" },
        { atribut: "atribute_2", label: "", tooltips: "" },
        { atribut: "atribute_3", label: "", tooltips: "" },
        { atribut: "atribute_4", label: "", tooltips: "" },
        { atribut: "atribute_5", label: "", tooltips: "" },
      ],

      showLoading: false,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.htarea {
  height: 45px;
}
</style>

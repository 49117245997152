<template>
  <div>
    <b-card>
      <!-- <b-tabs  -->
      <b-tabs>
        <hr />
        <b-tab title="SINOP"><sinop /></b-tab>
        <b-tab title="METAR/SPECI"> <metar /></b-tab>
        <b-tab title="PIBAL"> <pibal /></b-tab>
        <b-tab title="FDIH"> <fdih /> </b-tab>
        <b-tab title="FORM"> <formpias /> </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from "bootstrap-vue";
import Sinop from "./Componentooltips/Tooltipssinop.vue";
import Metar from "./Componentooltips/Tooltipsmetar.vue";
import Pibal from "./Componentooltips/Tooltipspibal.vue";
import Fdih from "./Componentooltips/ComTooltipsfdih/Tabtooltipsfdih.vue";
import Formpias from "./Componentooltips/ComTooltipspias/Tabtooltipspias.vue";

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    sinop: Sinop,
    metar: Metar,
    fdih: Fdih,
    pibal: Pibal,
    formpias: Formpias,
  },
};
</script>

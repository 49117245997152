<template>
  <div>
    <b-card no-body>
      <b-row>
        <b-col sm="12">
          <b-tabs vertical nav-wrapper-class="nav-vertical">
            <b-tab title="07.00 WS" active> <fdih07 /></b-tab>
            <b-tab title="07.30 WS"> <fdih0730 /></b-tab>
            <b-tab title="13.00 WS"> <fdih13 /></b-tab>
            <b-tab title="13.30 WS"> <fdih1330 /></b-tab>
            <b-tab title="14.00 WS"> <fdih14 /></b-tab>
            <b-tab title="17.00 WS"> <fdih17 /></b-tab>
            <b-tab title="17.30 WS"> <fdih1730 /></b-tab>
            <b-tab title="18.00 WS"> <fdih18 /></b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BTab, BTabs, BRow, BCol, BCard } from "bootstrap-vue";
import FDIH07 from "./07_00WS.vue";
import FDIH0730 from "./07_30WS.vue";
import FDIH13 from "./13_00WS.vue";
import FDIH1330 from "./13_30WS.vue";
import FDIH14 from "./14_00WS.vue";
import FDIH17 from "./17_00WS.vue";
import FDIH1730 from "./17_30WS.vue";
import FDIH18 from "./18_00WS.vue";

export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BTabs,
    fdih07: FDIH07,
    fdih0730: FDIH0730,
    fdih13: FDIH13,
    fdih1330: FDIH1330,
    fdih14: FDIH14,
    fdih17: FDIH17,
    fdih1730: FDIH1730,
    fdih18: FDIH18,
    // Station,
  },
};
</script>

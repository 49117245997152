import axios from 'axios'
import jwt from '@/auth/jwt/useJwt';
import helper from './helper';

export default {

    async getTooltips(type) {
        const url = helper.getBaseUrl() + "/@search?type_name=SettingTooltip&tp_form_type="+type+"&_size=200";
        let data = null;
        await axios.get(url, helper.getConfig())
        .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {
            });
        let items = data.items;
        var result = {}
        for(var item in items){
            let group = items[item].tp_group
            if(group == "{{tp_group}}"){
                group = "";
            }
            let tooltips = {
                "id" : items[item].tp_id,
                "attribut" : items[item].tp_attribut,
                "label" : items[item].tp_label,
                "group" : group,
                "tooltips" : items[item].tp_tootips                
            }
            result[items[item].tp_id] = tooltips;
        }
        return result;
    },
}
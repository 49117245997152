import { render, staticRenderFns } from "./Aktinograph.vue?vue&type=template&id=24d683e5&scoped=true&"
import script from "./Aktinograph.vue?vue&type=script&lang=js&"
export * from "./Aktinograph.vue?vue&type=script&lang=js&"
import style0 from "./Aktinograph.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d683e5",
  null
  
)

export default component.exports
<template>
  <div>
    
    <b-card no-body>
      <b-row>
        <b-col sm="12">
          <b-row>
              
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <!-- card2 -->
          <b-card no-body class="border">
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon icon="FolderIcon" size="14" />
                <span class="align-middle ml-50 text-dark h6">List Tooltips</span>
              </b-card-title>
            </b-card-header>
            <b-row class="">
              <b-col cols="12">
                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="Type to filter data"
                ></b-form-input>
                <b-table :items="items" :fields="fields" 
                 :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  @filtered="onFiltered"
                  thead-class="text-center" small>
                  <template #cell(atribut)="row">
                    <span class="text-dark small w-100"> {{ row.item.atribut }}</span>
                  </template>

                  <template #cell(label)="row">
                    <!-- <b-form-input size="sm" type="text" v-model="row.item.label" class="text-center" /> -->
                    <span class="text-dark small w-100"> {{ row.item.label }}</span>
                  </template>

                  <template #cell(tooltips)="row">
                    <!-- <b-form-input size="sm" type="text" v-model="row.item.tooltips" class="text-center" /> -->
                    <b-form-textarea rows="3" id="textarea-small" v-model="row.item.tooltips" />
                  </template>
                  <template #cell(ubah)="row">
                    <!-- <b-form-input size="sm" type="text" v-model="row.item.label" class="text-center" /> -->
                    <!-- <span class="text-dark small w-100"> xx - {{ row.item.ubah }}</span> -->
                    <b-button class="btn btn-warning btn-sm mr-2 float-right" @click="editData(row.item.ubah)">
                      <feather-icon icon="Edit2Icon"  />
                    </b-button>
                  </template>
                </b-table>
                
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <!-- Action Buttons -->
        <b-col cols="4" ></b-col>
        <b-col cols="4" >
          <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
              ></b-pagination>
        </b-col>
        <b-col cols="4" ></b-col>
      </b-row>
    </b-card>
    <b-modal
      id="modal-edit-tooltips"
      ref="modal-edit-tooltips"
      title="Ubah tooltips"
      @ok="handleOk"
      ok-title="Ubah"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-row class="my-1">
          <b-col sm="2">
            <label for="input-small">Attribut</label>
          </b-col>
          <b-col sm="10">
            <label for="input-small">: {{this.attribut_show}}</label>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col sm="2">
            <label for="input-small">Label</label>
          </b-col>
          <b-col sm="10">
            <label for="input-small">: {{this.label_show}}</label>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col sm="2">
            <label for="input-small">Group</label>
          </b-col>
          <b-col sm="10">
            <label for="input-small">: {{this.group_show}}</label>
          </b-col>
        </b-row>
        <b-form-group
          label="Name"
          label-for="name-input"
        >
          <!-- <b-form-input
            id="name-input"
            v-model="name"
          ></b-form-input> -->
          <b-form-textarea rows="3" v-model="tooltips_show"></b-form-textarea>
        </b-form-group>
      </form>
    </b-modal>
  </div>
  
</template>

<script>
import { BButton, BRow, BPagination, BCol, BFormGroup, BFormInput, BForm, BCard, BCardHeader, BCardTitle, BFormTextarea, BCardText, BTable } from "bootstrap-vue";
import vSelect from "vue-select";
import tooltips from '@/api/tooltips';
export default {
  components: {
    BButton,
    BRow,
    BPagination,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormTextarea,
    BCardText,
    vSelect,
    BTable,
  },

  data() {
    return {
      
      items: [{}],
      raw_data : {},
      showLoading: false,
      perPage: 10,
      currentPage: 1,
      rows: 1,
      filter: null,
      attribut_show : "",
      label_show : "",
      tooltips_show :"",
      group_show : "",
    };
  },
  mounted(){
    this.getDataTooltips();
  },
  methods: {
    handleOk(bvModalEvt){
      bvModalEvt.preventDefault()
      if(this.tooltips_show == ""){
        alert("kosong");
      }else{
        alert("okok");
      }
      
      
    },
    editData(id){
      let show = this.raw_data[id]
      this.attribut_show = show.attribut;
      this.label_show = show.label;
      this.group_show = show.group;
      this.tooltips_show = show.tooltips;
      this.$refs['modal-edit-tooltips'].show();
      
    },
    async getDataTooltips(){
     let data = await tooltips.getTooltips("sinoptik");
     console.log("data : ",data);
     this.raw_data = data;
     let data_items = [];
     for(var i in data){
       let row = {                   
          "attribut" :  data[i].attribut,
          "label" :  data[i].label,
          "group" :  data[i].group,
          "tooltips" :  data[i].tooltips,
          "ubah" : data[i].id                    
       }
       data_items.push(row);      
     }
     this.items = data_items;
     this.rows = this.items.length;
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length
      this.currentPage = 1
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.htarea {
  height: 45px;
}
</style>
